/* eslint-disable  */
import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import ajax, { abortAllRequest } from '../utils/ajaxHelper';
import { getHostType } from '../utils';
import { string } from 'prop-types';
import { useContext } from 'react';
import { TaskContext } from '../contexts/taskContext';
import { TASK_EVENT } from '../constants/actionTypes';

const useLogin = () => {
  const { company, token, roleId, registerToken } = useParams();
  const [taskState, taskDispatch] = useContext(TaskContext);
  const navigate = useNavigate();

  const callAPi = useCallback(
    async (url, values, { resetForm, setErrors, setStatus, setSubmitting, setFieldError }) => {
      setSubmitting(true);
      let data = values;

      try {
        let companyDetail = {};

        // if (token) {
        //   await ajax.post(
        //     'user/login',
        //     {
        //       token,
        //     },
        //     {
        //       networkParams: { showLoader: false },
        //     },
        //   );
        //   data = { ...values, token };
        // }

        if (registerToken) {
          await ajax.post(
            'register-token-validate',
            {
              token: registerToken,
            },
            {
              networkParams: { showLoader: false },
            },
          );
          data = { ...values };
          data.token = registerToken;
        }
        console.log(url, 200);
        const response = await ajax.post(url, data, {
          networkParams: { showLoader: false },
          headers: {
            ...companyDetail,
            hosttype: getHostType(roleId),
          },
        });
        if (!response.fieldMessage) {
          resetForm();
        }
        setStatus({ success: true });

        return response;
      } catch (error) {
        console.log(error);
        setStatus({ success: false });
        setErrors({ submit: error.message });
        throw error;
      } finally {
        setSubmitting(false);
      }
    },
    [token, company, roleId, registerToken],
  );

  const callAPI = useCallback(
    async (url, values) => {
      let data = values;

      try {
        let companyDetail = {};

        if (registerToken) {
          await ajax.post(
            'register-token-validate',
            {
              token: registerToken,
            },
            {
              networkParams: { showLoader: false },
            },
          );
          data = { ...values };
          data.token = registerToken;
        }
        console.log(url, 200);
        const response = await ajax.post(url, data, {
          networkParams: { showLoader: false },
          headers: {
            ...companyDetail,
            hosttype: getHostType(roleId),
          },
        });

        return response;
      } catch (error) {
        console.log(error);

        throw error;
      }
    },
    [token, company, roleId, registerToken],
  );

  // const login = useCallback(
  //   async (values, actions, callback) => {
  //     console.log(values,18000);
  //     try {
  //      const result =  await ajax.post('user/login', values);
  //     //  let result = await ajax.post('user/login', formData, {
  //     //   headers: {
  //     //     'Content-Type': 'multipart/form-data',
  //     //   },
  //     // });
  //      return result;
  //     } catch (error) { console.log(error, 404);}
  //   },
  //   [callAPi],
  //   //navigate('/login');
  // );

  const signup = useCallback(
    async (values, actions, ref, invitationDetails) => {
      try {
        const signUpResult = await callAPi('user/sign-up', values, actions);

        if (signUpResult.deviceAuth && signUpResult.status) {
          window.location.reload(false);
        } /* else if (loginResult.deviceAuth === false && loginResult.status) {
          navigate('../../two-factor-authentication', { state: values });
        } */ else if (
          signUpResult.status
        ) {
          actions.setFieldError(signUpResult.field, signUpResult.fieldMessage);
        }

        // ref.reset();
        console.log('RRR');
        if (invitationDetails?.emailId) {
          navigate(`/app/onboarding-confirmation/${invitationDetails?.id}`);
        }
        // navigate('../../app/home-in');
      } catch (error) {
        // ref.reset();
        console.log('error', error);
      }
    },
    [callAPi, navigate],
  );

  const login = useCallback(
    async (values, actions, ref) => {
      try {
        const signUpResult = await callAPi('user/login', values, actions);

        if (signUpResult.deviceAuth && signUpResult.status) {
          const authTokenDb = Cookies.get('authToken');
          Cookies.set('authToken', decodeURIComponent(authTokenDb), {
            expires: 1 / 24,
            domain: '.movingtohappy.net',
          });
          window.location.reload(false);
          navigate('../../app/account');
        } /* else if (loginResult.deviceAuth === false && loginResult.status) {
          navigate('../../two-factor-authentication', { state: values });
        } */ else if (
          signUpResult.status
        ) {
          actions.setFieldError(signUpResult.field, signUpResult.fieldMessage);
        }

        // ref.reset();
      } catch (error) {
        // ref.reset();
        console.log('error', error);
      }
    },
    [callAPi, navigate],
  );

  const autologin = useCallback(
    async (val) => {
      try {
        const values = { authTOken: val };

        const loginResult = await callAPI('user/auto-login', values); // Send SSOId in the request

        if (loginResult.status) {
          window.location.reload(false);
          console.log('TTT');
          navigate('../../app/account');
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    [callAPI, navigate],
  );

  const resetPasswordMail = useCallback(
    async (values, actions, ref) => {
      try {
        await callAPi('user/forgot-password', values, actions);
        navigate('../check-your-email');
        // ref.reset();
      } catch (e) {
        console.log('error');
        // ref.reset();
      }
    },
    [callAPi],
  );

  const resetpassword = useCallback(
    async (values, actions) => {
      await callAPi('user/reset-password', values, actions);
      console.log('RRR');
      navigate('/app/home-page');
    },
    [callAPi, navigate],
  );

  const changePassword = useCallback(
    async (values, actions) => {
      return await callAPi('user/change-password', values, actions);
    },
    [callAPi],
  );

  const changeEmail = useCallback(
    async (values, actions) => {
      await callAPi('user/change-email', values, actions);
      navigate('../change-email-sent');
    },
    [callAPi],
  );

  const verifyChangeEmail = useCallback(
    async (values, actions) => {
      await ajax.post(
        'user/verify-email',
        {
          token: values.token,
          emailId: values.emailId,
        },
        {
          networkParams: { showLoader: false },
        },
      );
      navigate('../../account');
    },
    [navigate],
  );

  const verify = useCallback(
    async (values, actions) => {
      await ajax.post(
        'user/verify',
        {
          token: values.token,
        },
        {
          networkParams: { showLoader: false },
        },
      );
      navigate('/app/home-page');
    },
    [navigate],
  );

  const passwordTokenVerify = useCallback(
    async (values, actions) => {
      await ajax.post(
        'user/verify-password-token',
        {
          token: values.token,
        },
        {
          networkParams: { showLoader: false },
        },
      );
      console.log('RRR');
      navigate('/app/home-page');
    },
    [navigate],
  );

  const setUserPassword = useCallback(
    async (values, actions) => {
      await callAPi('set-user-password', values, actions);
      navigate('../../login');
    },
    [callAPi, navigate],
  );

  const twoFactor = useCallback(
    async (values, actions) => {
      try {
        values.type = 'resend';
        const loginResult = await callAPi('login', values, actions);
        if (loginResult.deviceAuth && loginResult.status) {
          window.location.reload(false);
          setTimeout(function () {
            navigate('../app');
          }, 50);
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    [callAPi, navigate],
  );

  const submitOTP = useCallback(
    async (values, actions) => {
      try {
        const otpResult = await callAPi('verify-otp-and-login', values, actions);

        if (otpResult.deviceAuth && otpResult.status) {
          window.location.reload(false);
          setTimeout(function () {
            navigate('../app');
          }, 50);
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    [callAPi, navigate],
  );
  const abortAllRequest = (source) => {
    source.cancel(); ///Operation canceled by the user.
  };

  const deleteAccount = useCallback(
    async (values, actions) => {
      try {
        await callAPi('user/delete-user', values, actions);
      } catch (error) {
        console.error('Error deleting account:', error);
      } finally {
        const domains = [
          '.movingtohappy.net',
          'movetohappy-dev.azul-arc.com',
          'movetohappy-stg.azul-arc.com',
          'movetohappy-uat.azul-arc.com',
        ]; // Include all relevant domains
        const path = '/';

        // Function to delete a cookie for a given name, domain, and path
        const deleteCookie = (name, domain) => {
          if (domain) {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${domain};`;
          } else {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
          }
        };

        // List of cookies to remove
        const cookies = ['token', 'authToken', 'hosttype', 'user', 'path'];

        // Remove each cookie for each domain
        domains.forEach((domain) => {
          cookies.forEach((cookie) => {
            deleteCookie(cookie, domain);
            // If using js-cookie
            Cookies.remove(cookie, { path, domain });
          });
        });

        // Also remove cookies for localhost without specifying domain
        cookies.forEach((cookie) => {
          deleteCookie(cookie, null);
          // If using js-cookie
          Cookies.remove(cookie, { path });
        });

        console.log('Cookies removed');
        navigate('../../delete-account-success', { replace: true });
      }
    },
    [navigate],
  );

  const logout = useCallback(async () => {
    try {
      if (taskState && taskState.pendingBucket.length > 0) {
        abortAllRequest(taskState.sourceToken);
      }

      taskDispatch({
        type: TASK_EVENT.DRAWER_CLOSE,
        payload: null,
      });

      const domain = window.location.hostname === 'localhost' ? 'localhost' : '.movingtohappy.net';

      document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
      document.cookie = `authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
      document.cookie = `hosttype=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
      document.cookie = `user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
      document.cookie = `path=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
      document.cookie = `planetRe=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;

      // document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      // document.cookie = `authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      // document.cookie = `hosttype=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      // document.cookie = `user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      // document.cookie = `path=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      // document.cookie = `planetRe=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

      // Optionally, clear other cookies as needed
      // Redirect the user
      // windows.location.reload();
      navigate('/app/home-page', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
      // Handle error if needed
    }
  }, [navigate, taskState]);

  const keepActiveSession = useCallback(async () => {
    try {
      await ajax.post('keep-active');
    } catch (error) {
    } finally {
    }
  }, []);

  const getRoleData = useCallback(async (val) => {
    try {
      const values = { userId: val.userId };
      return await ajax.post(`user/get-user-details`, values);
    } catch (error) {
      return error;
    }
  });

  const getUserDataToAdmin = useCallback(async (val) => {
    try {
      const values = { userIdd: val.userIdd };
      return await ajax.post(`user/get-user-details-to-admin`, values);
    } catch (error) {
      return error;
    }
  });

  const editUserPersonalDetails = useCallback(async (values) => {
    console.log(values, 200);
    try {
      return await ajax.post(`user/update-user-personal-details`, values, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      return error;
    }
  });

  const editUserPersonalDetailsByAdmin = useCallback(async (values) => {
    console.log(values, 200);
    try {
      return await ajax.post(`user/update-user-personal-details`, values, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      return error;
    }
  });

  const editCommunityReps = useCallback(async (values) => {
    console.log(values, 200);
    try {
      return await ajax.post(`user/update-user-role`, values, {});
    } catch (error) {
      return error;
    }
  });

  const getInviteDetails = useCallback(async (values) => {
    try {
      return await ajax.post(`user/invite-details`, values);
    } catch (error) {
      return error;
    }
  });

  const declineInvitation = useCallback(async (values) => {
    try {
      return await ajax.post(`user/decline-invite`, values);
    } catch (error) {
      return error;
    }
  });

  const getDeclineInvitationById = useCallback(async (values) => {
    try {
      return await ajax.post(`user/get-decline-invitation-by-id`, values);
    } catch (error) {
      return error;
    }
  });

  const getInviteDetailsById = useCallback(async (values) => {
    try {
      return await ajax.post(`user/get-invitation-details`, values);
    } catch (error) {
      return error;
    }
  });

  const leaveCommunity = useCallback(async (values, actions) => {
    try {
      // await callAPi('user/leave-community', values, actions);
      return await ajax.post(`user/leave-community`, values, actions);
    } catch (error) {
      console.log(error, 'ERRR');
    } finally {
      console.log('RRR');
      navigate('/app/home-page', { replace: true });
    }
  });

  return {
    signup,
    login,
    resetPasswordMail,
    resetpassword,
    twoFactor,
    submitOTP,
    logout,
    setUserPassword,
    keepActiveSession,
    getRoleData,
    getUserDataToAdmin,
    verify,
    passwordTokenVerify,
    editUserPersonalDetails,
    editUserPersonalDetailsByAdmin,
    changePassword,
    changeEmail,
    verifyChangeEmail,
    deleteAccount,
    getInviteDetails,
    declineInvitation,
    getInviteDetailsById,
    editCommunityReps,
    leaveCommunity,
    getDeclineInvitationById,
    autologin,
  };
};

export default useLogin;
