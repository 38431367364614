/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  InputLabel,
  Container,
  Button,
  Typography,
  Box,
  Tab,
  Tabs,
  InputBase,
  InputAdornment,
  TextField,
  Popover,
  Paper,
  Divider,
  Checkbox,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../assets/images/Search-Icon.svg';
import { ReactComponent as DollarIcon } from '../../../assets/images/Dollar-Sign.svg';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import PropTypes from 'prop-types';
import { entityEnum } from '../../../constants/globalConstants';
import useCommunityList from '../../../hooks/useCommunityList';
import EmptyState from './EmptyState/EmptyState';
import '../Main.style.css';
import Pagination from '@material-ui/lab/Pagination';
import CommunityCard from './CommunityCard/CommunityCard';
import CardLoader from '../../../components/CardLoader/CardLoader';
import CommunityListSearch from './CommunityListSearch/CommunityListSearch';

const options = ['Filter 1', 'Filter 2', 'Filter 3', 'Filter 4'];
const ITEM_HEIGHT = 48;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const CommunityList = (props) => {
  const [communityList, setCommunityList] = useState(null);
  const [userCommunities, setUserCommunities] = useState(null);
  const [favCommunities, setFavCommunities] = React.useState([]);
  const [recCommunities, setRecCommunities] = React.useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const intialWhereValue = {
    states: [],
    minPopulation: -1,
    maxPopulation: -1,
    amenitiesType: [],
    costOfLivingType: [],
    //transportation
    publicTransType: [],
    dedicatedWalkingPaths: false,
    dedicatedBikingPaths: false,
    localAirport: false,
    regionalAirport: false,
    //education
    publicType: false,
    privateType: false,
    faithBasedPrivate: false,
    specialNeedsPrivate: false,
    publicTechnicalJuniorCollege: false,
    privateTechnicalJuniorCollege: false,
    publicFourYearCollegeUniversity: false,
    privatecFourYearCollegeUniversity: false,
    homeschoolResourceGroups: false,
    charter: false,
    facilityType: [],
  };
  const [where, setWhere] = React.useState(intialWhereValue);
  const [searchedText, setSearchedText] = useState('');
  const [tabValue, setTabValue] = React.useState(props?.tabValue);
  const { getAllCommunities, getCommunitiesByUser } = useCommunityList();
  let stateCommunitiesArr = [];
  let userCommunitiesArr = [];
  const favCommunitiesList = {};
  const recCommunitiesList = {};
  let filteredList = [];
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allCommunities, setAllCommunities] = useState([]);
  // const classes = useStyles();
  const key = 'iFrasCwdJh';

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);

  const createFavListObject = () => {
    if (userId !== null && userId !== undefined) {
      const userCommunitiesObject = userCommunitiesArr.usersResult;
      const communityListByStateObject = stateCommunitiesArr.usersResult;

      if (userCommunitiesObject) {
        const mergeArr = [];
        if (communityListByStateObject !== undefined) {
          for (let i = 0; i < communityListByStateObject?.length; i += 1) {
            mergeArr.push({
              ...communityListByStateObject[i],
              ...userCommunitiesObject.find(
                (itmInner) => itmInner.communityMapId.id === communityListByStateObject[i].id,
              ),
            });
          }
        }

        if (mergeArr?.length > 0) {
          mergeArr.forEach((elem) => {
            recCommunitiesList[
              `${elem?.communityMapId?.id === undefined ? elem?.id : elem?.communityMapId?.id}`
            ] = elem.isRecommended !== undefined ? Boolean(elem.isRecommended) : false;
            favCommunitiesList[
              `${elem?.communityMapId?.id === undefined ? elem?.id : elem?.communityMapId?.id}`
            ] = elem.isFavourite !== undefined ? Boolean(elem.isFavourite) : false;
          });
        }
        setFavCommunities(favCommunitiesList);
        setRecCommunities(recCommunitiesList);
      }
    }
    return favCommunitiesList;
  };

  const communityFilterFunction = (communityArray, _tabValue, searchedTextParam, _where = null) => {
    console.log('Debug:6', communityArray, _tabValue, searchedTextParam, _where);

    switch (_tabValue) {
      case 0: {
        filteredList = communityArray;
        break;
      }
      case 1: {
        if (userCommunities !== null && userCommunities?.length > 0) {
          const userCommObj = userCommunities.reduce(
            (acc, cur) => ({ ...acc, [cur?.communityMapId?.id]: cur?.readAt }),
            {},
          );

          communityArray
            .sort((a, b) => {
              return (
                new Date(userCommObj[b.id] || '1900-01-01') -
                new Date(userCommObj[a.id] || '1900-01-01')
              );
            })
            .forEach((clbs) => {
              if (userCommObj[clbs?.id] !== undefined) {
                /* eslint-disable no-param-reassign */
                clbs.readAt = userCommObj[clbs?.id];
                /* eslint-enable no-param-reassign */
              }
            });

          filteredList = communityArray;
        }
        break;
      }
      case 2: {
        console.log('Debug:3.1', { favCommunities });
        console.log('Debug:3.2', { userCommunities });
        if (userCommunities !== null && userCommunities?.length > 0) {
          filteredList = communityArray.filter((clbs) => favCommunities[clbs.id] === true);
        }

        break;
      }
      case 3: {
        if (userCommunities !== null && userCommunities?.length > 0) {
          filteredList = communityArray.filter((clbs) => {
            return recCommunities[clbs.id] === true;
          });
        }
        break;
      }
      default: {
        break;
      }
    }

    filteredList = filteredList?.filter((clbs) => {
      return (
        clbs?.communityNameText?.toLowerCase().indexOf(searchedTextParam.toLowerCase()) !== -1 ||
        clbs?.stateCommunities?.stateName
          ?.toLowerCase()
          .indexOf(searchedTextParam.toLowerCase()) !== -1
      );
    });
    setFilteredData(filteredList);
    // return filteredList;
  };

  const fillCommunityList = async (_where = null, _tabValue = null, searchText = null) => {
    if (stateCommunitiesArr?.length === 0) {
      stateCommunitiesArr = await getAllCommunities({
        ..._where,
        tab: _tabValue,
        searchText: searchText !== null ? searchText : searchedText,
        userID: props.userID,
      });

      stateCommunitiesArr?.dataResult?.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });

      setCommunityList(stateCommunitiesArr?.dataResult);
    }
    /* console.log('Debug:1 - calling fn', {
      communityArray: stateCommunitiesArr?.dataResult,
      _tabValue,
      tabValue,
      searchedTextParam: searchedText,
      _where,
    });
    communityFilterFunction(
      stateCommunitiesArr?.dataResult,
      _tabValue === null ? tabValue : _tabValue,
      searchedText,
    ); */
    // return stateCommunitiesArr?.usersResult;
  };

  const fillUserCommunities = async () => {
    if (userId !== null && userId !== undefined) {
      if (userCommunitiesArr?.length === 0) {
        userCommunitiesArr = await getCommunitiesByUser({
          userId,
          typeText: entityEnum[0],
        });
        // createFavListObject();
        setUserCommunities(userCommunitiesArr?.usersResult);
      }
    }
    return userCommunitiesArr?.usersResult;
  };
  const callFilterFromChild = async () => {
    console.log('Debug:2.1 - calling fn', {
      communityArray: communityList,
      _tabValue: tabValue,
      searchedTextParam: searchedText,
      _where: where,
    });
    await fillCommunityList(null, tabValue);
    // await fillUserCommunities();
    /* console.log('Debug:2 - calling fn', {
      communityArray: communityList,
      _tabValue: tabValue,
      searchedTextParam: searchedText,
      _where,
    });
    communityFilterFunction(communityList, tabValue, searchedText); */
  };

  const handleChange = async (event, newValue) => {
    setIsLoading(true);
    setTabValue(newValue);
    setSearchedText('');
    setCommunityList(null);
    setFilteredData(null);
    setTimeout(async () => {
      // if (userId !== null && userId !== undefined) {
      // createFavListObject();

      if (
        newValue === 0 ||
        newValue === 1 ||
        (userId && newValue === 2) ||
        (userId && newValue === 3)
      ) {
        await fillCommunityList(null, newValue);
      } else {
        setCommunityList([]);
      }
      // await fillUserCommunities();
      setIsLoading(false);
      // communityFilterFunction(communityList, tabValue, searchedText);
      // }
    }, 0);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearchChange = async (event) => {
    const searchText = event?.target?.value;
    setSearchedText(searchText);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newTimeout = await setTimeout(async () => {
      // setCurrentPage(1);
      await fillCommunityList(null, tabValue, searchText);
    }, 500); // 500ms delay

    setDebounceTimeout(newTimeout);
  };

  const clearSearch = async () => {
    setSearchedText('');
    console.log('Debug:4 - calling fn', {
      communityArray: communityList,
      _tabValue: tabValue,
      searchedTextParam: '',
    });
    await fillCommunityList(null, tabValue, '');
    // communityFilterFunction(communityList, tabValue, '');
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    console.log('Debug:2.3 - calling fn', {
      communityArray: communityList,
      _tabValue: tabValue,
      searchedTextParam: searchedText,
    });
    fillCommunityList(null, tabValue); // Tushar changes @Faisal
  }, [props]); // Tushar changes @Faisal

  useEffect(() => {
    // fillUserCommunities();
  }, []);

  useEffect(() => {
    // createFavListObject();
  }, []);

  const loadAllCommunities = async () => {
    const communityList = await getAllCommunities();

    setAllCommunities(communityList?.dataResult);
  };
  useEffect(() => {
    loadAllCommunities();
  }, []);

  return (
    <Box className="PageSpacing CommunityListPage">
      {props?.showSearch && (
        <>
          <Container>
            {console.log('123456', communityList)}
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography variant="h1" className="PageTitle BlackColor">
                  Communities
                </Typography>
              </Grid>
            </Grid>
            <Box mb={2} mt={2}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="Communities Tabs"
                className="CommonTabNav"
              >
                <Tab label="All" tabValue={0} {...tabProps(0)} />
                <Tab label="Recent" tabValue={1} {...tabProps(1)} />
                <Tab label="Favorites" tabValue={2} {...tabProps(2)} />
                <Tab label="Recommended" tabValue={3} {...tabProps(3)} />
              </Tabs>
            </Box>

            <form>
              <CommunityListSearch
                searchedText={searchedText}
                clearSearch={clearSearch}
                handleFilterClose={handleFilterClose}
                handleSearchChange={handleSearchChange}
                open={open}
                anchorEl={anchorEl}
                handleFilterClick={handleFilterClick}
                intialWhereValue={intialWhereValue}
                setWhere={setWhere}
                fillCommunityList={fillCommunityList}
                communityList={communityList}
                tabValue={tabValue}
                allCommunities={allCommunities}
              />
              <Box mb={2} className="ResultNumber">
                <Typography variant="body2" component="div">
                  {communityList?.length}
                  <Box component="span" ml={1}>
                    {communityList?.length <= 1 ? 'Result' : 'Results'}
                  </Box>
                </Typography>
              </Box>
            </form>
          </Container>
        </>
      )}
      <Container>
        {
          /* communityList?.length > 0 && */
          isLoading ? (
            <CardLoader />
          ) : (
            <Grid container direction="row" justifyContent="flex-start" alignItems="stretch">
              {communityList?.length > 0 ? (
                <>
                  {communityList?.map((cm) =>
                    userId ? (
                      <CommunityCard
                        cm={cm}
                        tabValue={tabValue}
                        listView
                        callFilterFromChild={callFilterFromChild}
                        updateFavObject={fillCommunityList}
                      />
                    ) : (
                      <CommunityCard cm={cm} listView updateFavObject={null} />
                    ),
                  )}
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Box className="PaginationWrapper BasicPagination">
                        <Typography variant="body2" component="span" className="PaginationNumber">
                          <Box component="span">1</Box>-<Box component="span">1</Box> of{' '}
                          <Box component="span">{communityList?.length}</Box> results
                        </Typography>
                        <Pagination count={1} color="primary" />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              ) : communityList != null ? (
                <EmptyState tabValue={tabValue} />
              ) : (
                ''
              )}
            </Grid>
          )
        }
      </Container>
    </Box>
  );
};
CommunityList.defaultProps = {
  showSearch: true,
  tabValue: 0,
  userID: null,
};
export default CommunityList;
